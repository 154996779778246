import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../Images/autoconnect-logo/Logo Autoconnect white.png";

export default function Navigation() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const hideNavigation = ["/hvala"];

  // Close Navigation when a page is opened
  const closeMenu = () => {
    setShow(false);
  };

  // Scroll to the top of the page when a page is opened
  const handleClick = (path) => {
    closeMenu();
    navigate(path);
    window.scrollTo(0, 0);
  };

  // Hide navigation if the location is /hvala
  if (hideNavigation.includes(location.pathname)) {
    return <></>;
  }

  return (
    <>
      <nav className="navbar">
        <Link to="/" onClick={() => handleClick("/")}>
          <img className="logo" src={Logo} alt="Autoconnect Logo" />
        </Link>
        <ul className={show ? "nav-links active" : "nav-links"}>
          <li>
            <Link
              to="/"
              onClick={() => handleClick("/")}
              id={location.pathname === "/" ? "active" : ""}
            >
              NASLOVNA
            </Link>
          </li>
          <div className="link-border"></div>
          <li>
            <Link
              to="/o-nama"
              onClick={() => handleClick("/")}
              id={location.pathname === "/o-nama" ? "active" : ""}
            >
              O NAMA
            </Link>
          </li>
          <div className="link-border"></div>
          <li>
            <Link
              to="/novosti"
              onClick={() => handleClick("/")}
              id={location.pathname === "/novosti" ? "active" : ""}
            >
              NOVOSTI
            </Link>
          </li>
          <div className="link-border"></div>
          <li>
            <Link
              to="/servis"
              onClick={() => handleClick("/")}
              id={location.pathname === "/servis" ? "active" : ""}
            >
              SERVIS
            </Link>
          </li>
          <div className="link-border"></div>
          <li>
            <Link
              to="/usluge"
              onClick={() => handleClick("/")}
              id={location.pathname === "/usluge" ? "active" : ""}
            >
              USLUGE
            </Link>
          </li>
          <div className="link-border"></div>
          <li>
            <Link
              to="/projekti"
              onClick={() => handleClick("/")}
              id={location.pathname === "/projekti" ? "active" : ""}
            >
              PROJEKTI
            </Link>
          </li>
          <div className="link-border"></div>
          <li>
            <Link
              to="/kontakt"
              onClick={() => handleClick("/")}
              id={location.pathname === "/kontakt" ? "active" : ""}
            >
              KONTAKT
            </Link>
          </li>
        </ul>
        <div
          className={show ? "hamburger active" : "hamburger"}
          onClick={() => setShow(!show)}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
    </>
  );
}
