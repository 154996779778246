// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Firebase configuration using environment variables from Cloudflare
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY, // Firebase API Key
  authDomain: process.env.REACT_APP_AUTH_DOMAIN, // Firebase Auth domain
  projectId: process.env.REACT_APP_PROJECT_ID, // Firebase project ID
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET, // Firebase storage bucket
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID, // Firebase messaging sender ID
  appId: process.env.REACT_APP_APP_ID, // Firebase App ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

// Initialize Auth
export const auth = getAuth(app);

export { db, storage };
