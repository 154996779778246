import ProjektiBackground from "../../../Images/galerija-auta/galerija-auta15.jpg";
import ProjektiSlider from "./ProjektiSlider";
import Main from "../Main/Main";
import { ProjektiGallery } from "../..";
import { useEffect } from "react";

export default function Projekti() {
  useEffect(() => {
    document.title = "Autoconnect | Projekti";
  }, []);
  return (
    <>
      <Main imageUrl={ProjektiBackground} altText="Background projekti" />
      <ProjektiSlider />
      <ProjektiGallery />
    </>
  );
}
