import ServisniCentar1 from "../../../Images/ostalo/Servis motora.jpg";
import ServisniCentar2 from "../../../Images/ostalo/Servis-car.jpg";
import { useState } from "react";

export default function ServisniCentar() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };

  return (
    <>
      <section className="section servisni-centar">
        <h1>SERVISNI CENTAR VOZILA</h1>
        <p>
          Jeste li spremni pružiti svom vozilu uslugu koju traži? Zakažite
          održavanje ili popravak vozila upravo ovdje. Znamo da nema ništa
          frustrirajuće nego biti bez svog vozila dok se ne popravi. Zato imamo
          osoblje koje se ističe u pružanju vrhunskog održavanja i popravka i
          sposobno je to učiniti brzo.
        </p>

        <div>
          <h3 style={{ marginTop: "30px" }}>NAŠE USLUGE UKLJUČUJU:</h3>
          <div className="servis-grid">
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-settings"></i>
              <h5>Generalni popravak automobila</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-car-alt"></i>
              <h5>Preventivno održavanje automobila</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-thermometer-half"></i>
              <h5>Servis klima uređaja i grijača</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-snowflake"></i>
              <h5>Popravak rashladnog sustava i hladnjaka</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-oil-temp"></i>
              <h5>Zamjena motornog ulja</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-oil-can"></i>
              <h5>Zamjena filtera za ulje</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-tire"></i>
              <h5>Popravak kočnica</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-engine-warning"></i>
              <h5>Dijagnostika motora</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-data-transfer"></i>
              <h5>Usluge prijenosa</h5>
            </div>
          </div>
        </div>
        <div className="servis-img">
          <img
            src={ServisniCentar1}
            alt="Serivsni centar"
            onClick={() => openImage(ServisniCentar1)}
          />
          <img
            src={ServisniCentar2}
            alt="Serivsni centar"
            onClick={() => openImage(ServisniCentar2)}
          />
        </div>
        {/* *****SHOW IMAGES ON CLICK***** */}
        {showImage && (
          <div id="overlay" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <img
                id="popupImage"
                src={showImage}
                style={{ width: "100%", height: "100%" }}
                alt="Show"
              />
            </div>
          </div>
        )}

        {/* <!--  --> */}
      </section>
    </>
  );
}
