import React, { useEffect, useState } from "react";
import News from "./News"; // Ensure this path is correct
import Main from "../Main/Main";
import KontaktBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

export default function NewsPages() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterSubtitle, setFilterSubtitle] = useState("Sve vijesti");
  const newsPerPage = 4;
  const [message, setMessage] = useState(""); // Add state for message

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        // Fetch the specific document containing the novosti array
        const docRef = doc(db, "autoconnect", "autoconnect"); // Reference to the specific document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const newsList = data.novosti || []; // Access the novosti array

          // Sort the newsList by date in descending order
          newsList.sort((a, b) => new Date(b.date) - new Date(a.date));

          setNewsData(newsList);
        } else {
          setMessage("Trenutno nema obavijesti.");
        }
      } catch (error) {
        setMessage(
          "Došlo je do greške prilikom dohvaćanja novosti: " + error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
  }, []);

  // Function to filter news based on category
  const filteredNewsData = newsData.filter((newsItem) => {
    if (filterSubtitle === "Sve vijesti") {
      return true;
    } else if (filterSubtitle === "Ostalo") {
      return (
        newsItem.category !== "Novosti" &&
        newsItem.category !== "Usluge" &&
        newsItem.category !== "Sound Modul"
      );
    } else {
      return newsItem.category === filterSubtitle;
    }
  });

  const totalNews = filteredNewsData.length;
  const totalPages = Math.ceil(totalNews / newsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFilterChange = (event) => {
    setFilterSubtitle(event.target.value);
    setCurrentPage(1);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePagination(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;

  const currentNews = filteredNewsData
    .slice(indexOfFirstNews, indexOfLastNews)
    .map((newsItem) => (
      <News
        key={newsItem.slug}
        slug={newsItem.slug} // Assuming each news item has a slug
        title={newsItem.title || "Naslov nije dostupan"} // Assuming title is in your Firestore data
        subtitle={newsItem.category || "Podnaslov nije dostupan"} // Using category as subtitle
        image={newsItem.mainImage} // Use mainImage from your Firestore data
      />
    ));

  if (loading) {
    return <div className="center">Učitavanje...</div>; // Loading state
  }

  return (
    <>
      <Main imageUrl={KontaktBackground} altText="Novosti" />
      <div className="section">
        {message && <div className="message">{message}</div>}{" "}
        {/* Display message if there is any */}
        <div className="filter-controls">
          <select
            id="subtitleFilter"
            value={filterSubtitle}
            onChange={handleFilterChange}
            className="filter-btn go-back-btn"
          >
            <option selected disabled>
              Filtriraj
            </option>
            <option value="Sve vijesti">Sve vijesti</option>
            <option value="Novosti">Novosti</option>
            <option value="Usluge">Usluge</option>
            <option value="Sound Modul">Sound Modul</option>
            <option value="Ostalo">Ostalo</option>
          </select>
        </div>
        <div className="grid-2 novosti-pages">{currentNews}</div>
        <div className="pagination">
          <button
            onClick={() =>
              handlePagination(currentPage > 1 ? currentPage - 1 : 1)
            }
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {renderPaginationButtons()}
          <button
            onClick={() =>
              handlePagination(
                currentPage < totalPages ? currentPage + 1 : totalPages
              )
            }
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  );
}
