import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../../firebaseConfig"; // Import Firestore
import { doc, getDoc } from "firebase/firestore"; // Import getDoc function
import KontaktBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import AutoConnect from "../../../Images/autoconnect-logo/Logo Autoconnect white.png";
import Main from "../Main/Main";

export default function NewsDetails() {
  const { slug } = useParams(); // Get the slug from the URL
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const docRef = doc(db, "autoconnect", "autoconnect"); // Reference to the specific document
        const docSnap = await getDoc(docRef);

        // Check if the document exists
        if (docSnap.exists()) {
          const data = docSnap.data();
          const novosti = data.novosti || []; // Access the novosti array

          // Find the specific news item that matches the slug
          const matchedNews = novosti.find((item) => item.slug === slug);

          if (matchedNews) {
            setNews(matchedNews); // Set the matched news item
          } else {
            setError("Novost nije pronađena!"); // Handle case where no document matches the slug
          }
        } else {
          setError("Dokument nije pronađen!"); // Handle case where the document does not exist
        }
      } catch (error) {
        setError("Došlo je do pogreške prilikom dohvaćanja novosti.");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
    window.scrollTo(0, 0); // Scroll to top of page
  }, [slug]);

  const handleGoBack = () => {
    window.history.back();
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Main imageUrl={KontaktBackground} altText="Novosti" />
      <div className="section left-margin">
        {/* Loading and Error States */}
        <Link to="/novosti" className="btn go-back-btn" onClick={handleGoBack}>
          Vrati se natrag
        </Link>
        {loading && <div className="center">Učitavanje...</div>}{" "}
        {/* Loading state */}
        {error && (
          <div className="news-not-found">
            <img src={AutoConnect} alt="AutoConnect" loading="lazy" />
            <h2>{error}</h2>
          </div>
        )}
        {!loading && !error && news && (
          <div className="news-preview top-margin">
            {news.mainImage && (
              <img
                src={news.mainImage}
                alt={news.imageAlt}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.newsAuthor && (
              <span className="image-author">{news.imageAuthor}</span>
            )}
            {news.subtitle && <p className="subtitle">{news.subtitle}</p>}
            {news.category && <div className="category">#{news.category}</div>}
            {news.title && <h3>{news.title}</h3>}
            <div className="novosti-text">
              {news.introTexts &&
                news.introTexts.map((text, index) => <p key={index}>{text}</p>)}
            </div>
            {/* Prikaz sekcija ako ih ima */}
            {news.sections &&
              news.sections.map((section, index) => (
                <div key={index}>
                  {section.image && (
                    <img
                      src={section.image}
                      alt={section.imageAlt || "NK Podsused"}
                      onContextMenu={preventContextMenu}
                      style={{ userSelect: "none" }}
                      loading="lazy"
                    />
                  )}
                  {section.imageAuthor && (
                    <span className="image-author">{section.imageAuthor}</span>
                  )}
                  {section.subtitle && <h4>{section.subtitle}</h4>}{" "}
                  {/* Displaying subtitle for each section */}
                  {section.text && <p>{section.text}</p>}
                </div>
              ))}
            <div className="novosti-text">
              {news.newsAuthor && <h5>{news.newsAuthor}</h5>}
            </div>
            {news.date && (
              <span className="date">
                {new Date(news.date).toLocaleDateString("hr-HR")}
              </span>
            )}{" "}
          </div>
        )}
      </div>
    </>
  );
}
