import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/App.scss";
import { HashRouter, Routes, Route } from "react-router-dom";
import {
  Footer,
  Kontakt,
  Naslovnica,
  Navigation,
  Onama,
  OpciUvjeti,
  PolitikaPrivatnosti,
  Projekti,
  Servis,
  Usluge,
  ZastupstvaLine,
  SoundModul,
  Brabus,
  Techart,
  Hamann,
  Login,
  AdminHome,
  AddNews,
  NewsPages,
  NewsDetails,
  AddCars,
  CarsPages,
} from "./Components";
import PrivateRoute from "./Components/Admin/LogInOut/PrivateRoute";
import { AuthProvider } from "./Components/Admin/LogInOut/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <AuthProvider>
      <Navigation />

      <Routes>
        {/* Pages */}
        <Route path="/" element={<Naslovnica />} />
        <Route path="/o-nama" element={<Onama />} />
        <Route path="/servis" element={<Servis />} />
        {/* NEWS */}
        <Route path="/novosti" element={<NewsPages />} />
        <Route path="/novosti/:slug" element={<NewsDetails />} />
        <Route path="/usluge" element={<Usluge />} />
        <Route path="/projekti" element={<Projekti />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/opci-uvjeti" element={<OpciUvjeti />} />
        <Route path="/politika-privatnosti" element={<PolitikaPrivatnosti />} />
        <Route path="/sound-modul" element={<SoundModul />} />
        <Route path="/prodaja-auta" element={<CarsPages />} />
        {/* Zastupstva */}
        <Route path="/zastupstva/brabus" element={<Brabus />} />
        <Route path="/zastupstva/hamann" element={<Hamann />} />
        <Route path="/zastupstva/techart" element={<Techart />} />

        {/* ADMIN */}
        <Route path="/prijava" element={<Login />} />

        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminHome />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/dodaj-novost"
          element={
            <PrivateRoute>
              <AddNews />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/prodaj-aute"
          element={
            <PrivateRoute>
              <AddCars />
            </PrivateRoute>
          }
        />
      </Routes>
      <ZastupstvaLine />
      <Footer />
    </AuthProvider>
  </HashRouter>
);
