// Zastupstva Images
import Amg from "../../../Images/zastupstva/amg.png";
import Brabus from "../../../Images/zastupstva/brabus.png";
import Hamann from "../../../Images/zastupstva/hamann.png";
import MercedesBenz from "../../../Images/zastupstva/mercedes-benz.png";
import Startech from "../../../Images/zastupstva/startech.png";
import Techart from "../../../Images/zastupstva/techart.png";
import { useLocation } from "react-router-dom";

export default function ZastupstvaLine() {
  const location = useLocation();
  const hideNavigation = ["/hvala"];

  // Hide navigation if the location is /hvala
  if (hideNavigation.includes(location.pathname)) {
    return <></>;
  }
  return (
    <>
      <section className="section-brands">
        <div className="brands-grid">
          <img src={Amg} alt="amg" />
          <img src={Brabus} alt="brabus" />
          <img src={Hamann} alt="hamann" />
          <img src={MercedesBenz} alt="mercedes-benz" />
          <img src={Startech} alt="startech" />
          <img src={Techart} alt="techart" />
        </div>
      </section>
    </>
  );
}
