import React from "react";

const NewsPreview = ({ article }) => {
  if (!article) {
    return <div>Preview nije dostupan.</div>;
  }

  return (
    <div className="section">
      <div className="news-preview">
        {/* Display main image if present */}
        {article.mainImage && (
          <img
            src={
              typeof article.mainImage === "string"
                ? article.mainImage
                : URL.createObjectURL(article.mainImage)
            }
            alt={article.imageAlt}
          />
        )}
        <span className="image-author">{article.imageAuthor}</span>
        <div className="category"># {article.category}</div>
        <h3>{article.title}</h3>

        {/* Display intro texts */}
        {article.introTexts.map((text, index) => (
          <p key={index}>{text}</p>
        ))}

        {/* Display sections with images and texts */}
        {article.sections.map((section, index) => (
          <div key={index}>
            {section.image && (
              <img
                src={
                  typeof section.image === "string"
                    ? section.image
                    : URL.createObjectURL(section.image)
                }
                alt={section.imageAlt}
              />
            )}
            <span className="image-author">{section.imageAuthor}</span>
            <h4>{section.subtitle}</h4>
            <p>{section.text}</p>
          </div>
        ))}

        {/* Display author and date */}
        <div className="novosti-text">
          <h5>{article.newsAuthor}</h5>
        </div>
        <span className="date">{article.date}</span>
      </div>
    </div>
  );
};

export default NewsPreview;
