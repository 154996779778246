// src/Components/AdminHome.js
import React from "react";
import KontaktBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import Main from "../../Pages/Main/Main";
import { Link } from "react-router-dom";

const AdminHome = () => {
  return (
    <div className="section">
      {" "}
      <Main imageUrl={KontaktBackground} altText="ADMIN LOGIN" />
      <h2>ADMIN PANEL</h2>
      <div className="admin-btn-group">
        <Link className="admin-btn" to="/admin/dodaj-novost">
          DODAJ NOVOSTI
        </Link>
        <Link className="admin-btn" to="/admin/prodaj-aute">
          PRODAJ AUTE
        </Link>

        <Link className="admin-btn" to="/">
          NASLOVNA
        </Link>
      </div>
    </div>
  );
};

export default AdminHome;
