export default function Main({ altText, imageUrl }) {
  return (
    <>
      <main>
        <div className="main-box"></div>
        <div className="main-img">
          <img src={imageUrl} alt={altText} />
        </div>
      </main>
    </>
  );
}
