import { useEffect } from "react";
import PolitikaBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import Main from "../Main/Main";

export default function PolitikaPrivatnosti() {
  useEffect(() => {
    document.title = "Autoconnect | Politika privatnosti";
  }, []);
  return (
    <>
      <Main imageUrl={PolitikaBackground} altText="Background privacy" />
      <section className="section">
        <h2>POLITIKA PRIVATNOSTI</h2>
        <div className="privacy-text">
          <p>
            Autoconnect d.o.o., OIB: 27909788832, Zagrebačka 33, Zagreb-Sesvete-
            u svom poslovanju prikuplja i obrađuje osobne podatke svojih
            korisnika usluga te poduzima mjere kako bi obrada osobnih podataka
            bila zakonita, poštena i transparentna. Prikupljanje, obrada i
            uporaba podataka provode se u skladu s propisima o zaštiti podataka,
            te pojmovi koji se koriste u ovoj Izjavi imaju značenje određeno
            Uredbom EU 2016/679.
          </p>
          <p>
            Ovu Internet stranicu možete pregledavati i koristiti anonimno jer
            se tom prilikom ne prikupljaju podaci koji bi omogućili Vašu
            identifikaciju kao pojedinca, te niste obvezni pružiti nam Vaše
            osobne podatke kao uvjet korištenja stranice.
          </p>
          <p>
            Upisom osobnih podataka na za to predviđena polja na Internet
            stranicama potvrđujete da ste svoje osobne podatke dobrovoljno
            stavili na raspolaganje te da dozvoljavate da se isti koriste u
            svrhu u koju su podaci dani.
          </p>
          <p>
            Vaše podatke obrađujemo i koristimo u svrhu izvršenja ugovora u
            kojem ste stranka i kako bi se poduzele radnje na Vaš zahtjev prije
            sklapanja ugovora ( npr. rezerviranje i najam vozila i sl.) te ako
            je obrada nužna radi poštovanja pravnih i zakonskih obveza
            Autoconnect-a (npr. vođenje računovodstvenih poslova, dostava Vaših
            podataka nadležnom tijelu ako je vozilom koje ste koristili počinjen
            prekršaj i sl.)
          </p>
          <p>
            Podatke koje smo prikupili od Vas i o Vama ostaju u bazama
            Autoconnect-a. Vaše podaci se mogu dostaviti trećim stranama kada je
            to naša obaveza prema zakonu ili kao odgovor na pravni postupak
            odnosno na zahtjev nadležnih institucija za provedbu zakona u vezi s
            prekršajnim, kaznenim ili sudskim postupcima i radi traženja
            odgovarajućih pravnih lijekova i ograničavanja štete koja nam može
            biti nanesena.
          </p>
          <p>
            Podaci za koje zakonima ili drugim propisima nije propisan rok
            pohrane, pohranjuju se za razumno razdoblje imajući u vidu
            kategoriju podataka i svrhu u koju su isti prikupljeni. Podaci
            prikupljeni u određenu svrhu koristit će se samo u tu svrhu te nakon
            isteka razumnog vremenskog razdoblja i nakon što se ta svrha ispuni
            više neće biti aktivno pohranjeni.
          </p>
        </div>
      </section>
    </>
  );
}
