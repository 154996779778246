import { useEffect, useState } from "react";
import Main from "../Main/Main";
import { HashLink } from "react-router-hash-link";
import { Link, useNavigate } from "react-router-dom";
// Language

// Images
import BrabusBackground from "../../../Images/zastupstva/brabus/Brabus2.png";
import BrabusImage from "../../../Images/zastupstva/brabus/Brabus.jpg";
import BrabusTextImage from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import BrabusTestiranje1 from "../../../Images/zastupstva/brabus/Brabus-testiranje.jpg";
import BrabusTestiranje2 from "../../../Images/zastupstva/brabus/Brabus-testiranje2.jpg";
import BrabusServis1 from "../../../Images/zastupstva/brabus/Brabus-servis1.jpg";
import BrabusServis2 from "../../../Images/zastupstva/brabus/Brabus-servis2.jpg";
import BrabusServis3 from "../../../Images/zastupstva/brabus/Brabus-servis3.jpg";
import BrabusServis4 from "../../../Images/zastupstva/brabus/Brabus-servis4.jpg";

export default function Brabus() {
  useEffect(() => {
    document.title = "Autoconnect | Brabus";
  }, []);
  return (
    <>
      <Main imageUrl={BrabusBackground} altText="Brabus" />
      <BrabusText />
    </>
  );
}

function BrabusText() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="section">
        <h2>BRABUS</h2>
        <div className="zastupstva-text">
          <p>
            Više od 40 godina Brabus razvija i izrađuje superautomobile visokih
            performansi i transformira vozila marke Mercedes-Benz u moderna,
            jedinstvena vozila izrađena u Njemačkoj.
          </p>
          {/*  */}
          <div className="zastupstva-grid">
            <img
              src={BrabusImage}
              alt="Brabus"
              onClick={() => openImage(BrabusImage)}
            />
            <img
              src={BrabusTextImage}
              alt="Brabus"
              onClick={() => openImage(BrabusTextImage)}
            />
          </div>
          {/*  */}
          <p>
            Tijekom četiri desetljeća, BRABUS je u određenom smislu zrcalio
            performanse svakog BRABUS motora: postoji snažan potisak prema
            naprijed i taj potisak ide dalje i dalje. Sve više i više zapremine,
            više konjskih snaga, više okretnog momenta. Sve više i više
            inovacija.
          </p>
          <p>
            Sve više i više internih sastanaka, više sigurnosti, više ekološke
            prihvatljivosti. Sve više i više ekskluzivnosti. I sve više zapisa.
          </p>
          <p>
            Strast prema automobilima, predanost izvedbi, želja za savršenstvom,
            težnja za superlativom, ali i osjećaj za estetiku i funkcionalnost
            pretvaraju serijske automobile u BRABUS automobile.
          </p>
          <p>
            Umjetnost je stvaranja automobila velikih i malih odražavaju
            vrhunsku tehnologiju i sigurnost – vrijeme za vremenom. Jednostavno
            vrhunsko.
          </p>
        </div>
        <div className="zastupstva-text">
          <h5>JEDAN MARKA – JEDAN TIM</h5>
          <p>
            Automobili visokih performansi, vrhunska podešavanja i stil života
            za Mercedes-Benz i smart domena su BRABUS-a. BRABUS GmbH, osnovan
            1977, najveći je neovisni svjetski specijalist za preradu automobila
            i proizvođač ekskluzivnih superautomobila.
          </p>
          {/*  */}
          <div className="zastupstva-grid">
            <img
              src={BrabusTestiranje1}
              alt="Brabus testiranje"
              onClick={() => openImage(BrabusTestiranje1)}
            />
            <img
              src={BrabusTestiranje2}
              alt="Brabus testiranje"
              onClick={() => openImage(BrabusTestiranje2)}
            />
          </div>
          <p>
            Svjetski poznati tuner i proizvođač automobila nudi prilagođena
            rješenja za bilo koji zahtjev: asortiman proizvoda BRABUS obuhvaća
            nadograđene motore i elegantno stilizirane setove za poboljšanje
            aerodinamike do visokokvalitetnih aluminijskih naplataka, sportskih,
            ali udobnih sportskih ovjesa i ekskluzivnih interijera na najvišoj
            razini sjedišta tvrtke BRABUS nalazi se u Bottropu, a osim
            prostranih prodajnih salona u njemu se nalaze i marketing i prodaja,
            administracija, kao i inženjerski i proizvodni odjel.
          </p>

          <Link to="https://www.brabus.com/">https://www.brabus.com/</Link>

          <div className="zastupstva-img-box">
            <img
              src={BrabusServis1}
              alt="Brabus"
              onClick={() => openImage(BrabusServis1)}
            />
            <img
              src={BrabusServis2}
              alt="Brabus"
              onClick={() => openImage(BrabusServis2)}
            />
            <img
              src={BrabusServis3}
              alt="Brabus"
              onClick={() => openImage(BrabusServis3)}
            />
            <img
              src={BrabusServis4}
              alt="Brabus"
              onClick={() => openImage(BrabusServis4)}
            />
          </div>
        </div>
        {/*  */}
        <div className="zastupstva-buttons">
          <HashLink
            smooth
            to="/zastupstva/hamann"
            className="btn"
            onClick={() => handleClick("/zastupstva/hamann")}
          >
            HAMANN &rarr;
          </HashLink>
          <HashLink
            smooth
            to="/zastupstva/techart"
            className="btn"
            onClick={() => handleClick("/zastupstva/techart")}
          >
            {" "}
            TECHART &rarr;
          </HashLink>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
