import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db, storage } from "../../../firebaseConfig";
import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import KontaktBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import NewsPreview from "./NewsPreview";
import Main from "../../Pages/Main/Main";

const AddNews = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const newsItem = location.state?.newsItem;

  // State management
  const [mainImage, setMainImage] = useState(null);
  const [imageAlt, setImageAlt] = useState("");
  const [imageAuthor, setImageAuthor] = useState("");
  const [category, setCategory] = useState("Odaberite");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [introTexts, setIntroTexts] = useState([""]);
  const [sections, setSections] = useState([]);
  const [newsAuthor, setNewsAuthor] = useState("");
  const [date, setDate] = useState("");
  const [previewData, setPreviewData] = useState(null);
  const [errors, setErrors] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);
  const [newsList, setNewsList] = useState([]);

  // Reference to scroll to top of the form
  const formRef = useRef();

  // Categories
  const categories = [
    "Odaberite",
    "Novosti",
    "Usluge",
    "Sound Modul",
    "Ostalo",
  ];

  // Format date function (simple example)
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Handle form data for editing
  useEffect(() => {
    if (newsItem) {
      setMainImage(newsItem.mainImage || null);
      setImageAlt(newsItem.imageAlt || "");
      setImageAuthor(newsItem.imageAuthor || "");
      setCategory(newsItem.category || "Odaberite");
      setTitle(newsItem.title || "");
      setSlug(newsItem.slug || "");
      setIntroTexts(newsItem.introTexts || []);
      setSections(newsItem.sections || []);
      setNewsAuthor(newsItem.newsAuthor || "");
      setDate(newsItem.date || "");
    }
  }, [newsItem]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [newsItem]);

  const addIntroText = () => {
    setIntroTexts([...introTexts, ""]);
  };

  const removeIntroText = (index) => {
    const updatedIntroTexts = introTexts.filter((_, i) => i !== index);
    setIntroTexts(updatedIntroTexts);
  };

  const addSection = () => {
    setSections([
      ...sections,
      { subtitle: "", text: "", image: null, imageAlt: "", imageAuthor: "" },
    ]);
  };

  const removeSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  const handlePreview = () => {
    const previewSections = sections.map((section) => ({
      ...section,
      image:
        section.image instanceof File
          ? URL.createObjectURL(section.image)
          : section.image,
    }));

    setPreviewData({
      mainImage:
        mainImage instanceof File ? URL.createObjectURL(mainImage) : mainImage,
      imageAlt,
      imageAuthor,
      category,
      title,
      slug,
      introTexts,
      sections: previewSections,
      newsAuthor,
      date: date || new Date().toISOString().split("T")[0], // default to today's date if not set
    });
  };

  const handleEdit = (newsItem) => {
    navigate("/admin/dodaj-novost", { state: { newsItem } });
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDelete = async (index) => {
    const updatedNewsList = newsList.filter((_, i) => i !== index);
    const docRef = doc(db, "autoconnect", "autoconnect");
    await updateDoc(docRef, { novosti: updatedNewsList });
    setNewsList(updatedNewsList);
  };

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "")
      .replace(/--+/g, "-")
      .replace(/^-|-$/g, "");
  };

  useEffect(() => {
    setSlug(generateSlug(title));
  }, [title]);

  useEffect(() => {
    const fetchNewsList = async () => {
      const docRef = doc(db, "autoconnect", "autoconnect");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setNewsList(docSnap.data().novosti || []);
      }
    };
    fetchNewsList();
  }, []);

  const handlePublish = async (e) => {
    e.preventDefault();
    if (formDisabled) return; // Prevent multiple submissions

    // Form validation
    const validationErrors = {};
    if (!mainImage) validationErrors.mainImage = "Main image is required.";
    if (!imageAlt)
      validationErrors.imageAlt = "Alt text for main image is required.";
    if (!title) validationErrors.title = "Title is required.";
    if (category === "Odaberite")
      validationErrors.category = "Category is required.";

    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const formattedDate = formatDate(new Date(date)); // Format date
    setFormDisabled(true);

    try {
      const newNewsItem = {
        imageAlt,
        imageAuthor,
        category,
        title,
        slug,
        introTexts,
        sections,
        newsAuthor,
        date: formattedDate,
      };

      if (mainImage) {
        const mainImageURL = await uploadImage(mainImage);
        newNewsItem.mainImage = mainImageURL;
      }

      const updatedSections = await Promise.all(
        sections.map(async (section) => {
          if (section.image) {
            const sectionImageURL = await uploadImage(section.image);
            return { ...section, image: sectionImageURL };
          }
          return section;
        })
      );
      newNewsItem.sections = updatedSections;

      const docRef = doc(db, "autoconnect", "autoconnect");

      if (newsItem) {
        // Update existing news item
        await updateDoc(docRef, {
          novosti: newsList.map((item) =>
            item.slug === newsItem.slug ? newNewsItem : item
          ),
        });
      } else {
        // Add new news item
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            novosti: [...docSnap.data().novosti, newNewsItem],
          });
        } else {
          await setDoc(docRef, { novosti: [newNewsItem] });
        }
      }
      navigate("/admin");
    } catch (error) {
      console.error("Error publishing news:", error);
    } finally {
      setFormDisabled(false);
    }
  };

  const uploadImage = async (imageFile) => {
    const storageRef = ref(storage, `images/${imageFile.name}`);
    await uploadBytes(storageRef, imageFile);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };
  return (
    <>
      <Main imageUrl={KontaktBackground} altText="Autoconnect" />
      <div className="section">
        <button className="btn" onClick={() => navigate("/admin")}>
          Vrati se nazad
        </button>
        <h2>{newsItem ? "Uredi Novost" : "Dodaj Novost"}</h2>

        <div className="form">
          <label>Glavna slika:</label>
          <input
            className={`news-img ${errors.mainImage ? "error" : ""}`}
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setMainImage(file);
                setErrors({ ...errors, mainImage: "" });
              }
            }}
          />
          {errors.mainImage && (
            <div className="error-message">{errors.mainImage}</div>
          )}

          <div className="news-image-alt">
            <input
              type="text"
              placeholder="Opis slike (alt)"
              value={imageAlt}
              onChange={(e) => setImageAlt(e.target.value)}
              className={errors.imageAlt ? "error" : ""}
            />
            <input
              type="text"
              placeholder="Autor slike"
              value={imageAuthor}
              onChange={(e) => setImageAuthor(e.target.value)}
            />
          </div>

          {errors.imageAlt && (
            <div className="error-message">{errors.imageAlt}</div>
          )}

          <label>Kategorija:</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
          {errors.category && (
            <div className="error-message">{errors.category}</div>
          )}

          <label>Glavni naslov:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Glavni naslov"
            className={errors.title ? "error" : ""}
          />
          {errors.title && <div className="error-message">{errors.title}</div>}
          <label>Uvodni tekst:</label>
          {introTexts.map((text, index) => (
            <div key={index}>
              <textarea
                type="text"
                value={text}
                placeholder="Uvodni tekst"
                onChange={(e) => {
                  const updatedIntroTexts = [...introTexts];
                  updatedIntroTexts[index] = e.target.value;
                  setIntroTexts(updatedIntroTexts);
                }}
              />
              <button
                className="remove-btn"
                onClick={() => removeIntroText(index)}
              >
                Ukloni uvodni tekst
              </button>
            </div>
          ))}
          <button className="add-btn" onClick={addIntroText}>
            Dodaj uvodni tekst
          </button>

          <div className="news-form">
            {sections.map((section, index) => (
              <div key={index}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const updatedSections = [...sections];
                      updatedSections[index].image = file;
                      setSections(updatedSections);
                    }
                  }}
                />
                <div className="news-image-alt">
                  <input
                    type="text"
                    value={section.imageAlt}
                    placeholder="Opis slike"
                    onChange={(e) => {
                      const updatedSections = [...sections];
                      updatedSections[index].imageAlt = e.target.value;
                      setSections(updatedSections);
                    }}
                  />
                  <input
                    type="text"
                    value={section.imageAuthor}
                    placeholder="Autor slike"
                    onChange={(e) => {
                      const updatedSections = [...sections];
                      updatedSections[index].imageAuthor = e.target.value;
                      setSections(updatedSections);
                    }}
                  />
                </div>
                <input
                  type="text"
                  value={section.subtitle}
                  placeholder="Podnaslov"
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[index].subtitle = e.target.value;
                    setSections(updatedSections);
                  }}
                />
                <textarea
                  value={section.text}
                  placeholder="Tekst sekcije"
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[index].text = e.target.value;
                    setSections(updatedSections);
                  }}
                />

                <button
                  className="remove-btn"
                  onClick={() => removeSection(index)}
                >
                  Ukloni sekciju
                </button>
              </div>
            ))}
            <button className="add-btn" onClick={addSection}>
              Dodaj sekciju
            </button>
          </div>

          <div className="news-form">
            <label>Autor novosti:</label>
            <input
              type="text"
              value={newsAuthor}
              placeholder="Autor"
              onChange={(e) => setNewsAuthor(e.target.value)}
            />
            <label>Datum:</label>
            <input
              type="date"
              id="date"
              name="Datum"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <div className="buttons">
            <button className="add-btn" onClick={handlePreview}>
              Pregled
            </button>
            <button
              className="publish-btn"
              onClick={handlePublish}
              disabled={formDisabled}
            >
              {newsItem ? "Spremi" : "Objavi"}
            </button>
          </div>
        </div>
        {previewData && <NewsPreview article={previewData} />}
        <div className="border"></div>
        <div>
          <h3>Lista Novosti</h3>
          {newsList.map((newsItem, index) => (
            <div key={index} className="admin-news-list">
              <img src={newsItem.mainImage} alt={newsItem.imageAuthor} />
              <h5>{newsItem.title}</h5>
              <div>
                <button
                  className="edit-btn"
                  onClick={() => handleEdit(newsItem)}
                >
                  Edit
                </button>
                <button
                  className="remove-btn"
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddNews;
