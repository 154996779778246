import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebaseConfig"; // Importing db and storage
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Functions for Firebase storage
import CarsPreview from "./CarsPreview"; // For previewing car data
import Main from "../../Pages/Main/Main";
import KontaktBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import { onSnapshot } from "firebase/firestore";

const AddCars = () => {
  const [carData, setCarData] = useState({
    images: [],
    carName: "",
    price: "",
    kilometers: "",
    enginePower: "",
    productionYear: "",
    carLink: "",
    category: "automobili", // Default category
    wheelWidth: "", // For Felge category - Širina
    wheelHeight: "", // For Felge category - Visina
    wheelDiameter: "", // For Felge category - Promjer
    numberOfHoles: "", // For Felge category - Broj rupa
  });

  const [carList, setCarList] = useState([]); // Local car list
  const [previewCarData, setPreviewCarData] = useState(null); // Preview data for the car
  const [selectedCategory, setSelectedCategory] = useState("all"); // State for filtering cars by category
  const [editCarId, setEditCarId] = useState(null); // ID of the car currently being edited

  // Fetch car data from Firestore when the component mounts
  useEffect(() => {
    const carRef = doc(db, "autoconnect", "autoconnect");
    const unsubscribe = onSnapshot(carRef, (docSnapshot) => {
      const data = docSnapshot.data();
      if (data && data.prodajaAuta) {
        setCarList(data.prodajaAuta);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setCarData({ ...carData, images: files });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarData({ ...carData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageUrls = [];
    for (const image of carData.images) {
      const imageRef = ref(storage, "carImages/" + image.name);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);
      imageUrls.push(imageUrl);
    }

    const carRef = doc(db, "autoconnect", "autoconnect");

    if (editCarId) {
      // Update existing car data
      const updatedCarList = carList.map((car) =>
        car.carLink === editCarId ? { ...carData, images: imageUrls } : car
      );

      await updateDoc(carRef, {
        prodajaAuta: updatedCarList,
      });

      setCarList(updatedCarList);
      setEditCarId(null); // Reset editCarId after saving
    } else {
      // Add new car data
      await updateDoc(carRef, {
        prodajaAuta: arrayUnion({
          ...carData,
          images: imageUrls,
        }),
      });

      setCarList([
        ...carList,
        {
          ...carData,
          images: imageUrls,
        },
      ]);
    }

    setCarData({
      images: [],
      carName: "",
      price: "",
      kilometers: "",
      enginePower: "",
      productionYear: "",
      carLink: "",
      category: "automobili", // Reset to default category
      wheelWidth: "",
      wheelHeight: "",
      wheelDiameter: "",
      numberOfHoles: "",
    });

    setPreviewCarData(null);
  };

  const handlePreview = () => {
    setPreviewCarData(carData);
  };

  const handleEditCar = (car) => {
    setCarData(car);
    setEditCarId(car.carLink); // Set car ID to indicate editing mode
    window.scrollTo(0, 0);
  };

  const handleDeleteCar = async (carToDelete) => {
    const carRef = doc(db, "autoconnect", "autoconnect");
    await updateDoc(carRef, {
      prodajaAuta: arrayRemove(carToDelete),
    });

    setCarList(carList.filter((car) => car !== carToDelete));
  };

  const handleCategoryFilter = (e) => {
    setSelectedCategory(e.target.value);
  };

  const filteredCarList =
    selectedCategory === "all"
      ? carList
      : carList.filter((car) => car.category === selectedCategory);

  return (
    <>
      <Main imageUrl={KontaktBackground} altText="Autoconnect" />
      <div className="section">
        <h1>Dodaj Vozilo</h1>
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageChange}
          />
          <label>Naziv:</label>
          <input
            type="text"
            name="carName"
            value={carData.carName}
            onChange={handleChange}
          />
          <label>Kategorija:</label>
          <select
            name="category"
            value={carData.category}
            onChange={handleChange}
          >
            <option value="automobili">Automobili</option>
            <option value="dijelovi">Dijelovi</option>
            <option value="felge">Felge</option>
            <option value="ostalo">Ostalo</option>
          </select>

          {/* Conditional fields based on category */}
          {carData.category === "automobili" && (
            <>
              <div className="input-grid-4">
                <input
                  type="number"
                  name="price"
                  value={carData.price}
                  onChange={handleChange}
                  placeholder="Cijena"
                />
                <p>€</p>

                <input
                  type="number"
                  name="kilometers"
                  placeholder="Kilometri"
                  value={carData.kilometers}
                  onChange={handleChange}
                />
                <p>km</p>
              </div>
              <div className="input-grid-4">
                <input
                  type="number"
                  name="enginePower"
                  value={carData.enginePower}
                  onChange={handleChange}
                  placeholder="Snaga motora"
                />
                <p>kW</p>
                <input
                  type="number"
                  name="productionYear"
                  value={carData.productionYear}
                  onChange={handleChange}
                  placeholder="Godina proizvodnje"
                />
                <p>god.</p>
              </div>
            </>
          )}

          {carData.category === "felge" && (
            <>
              <div className="input-grid-2">
                <input
                  type="number"
                  name="wheelWidth"
                  value={carData.wheelWidth}
                  onChange={handleChange}
                  placeholder="Širina"
                />
                <input
                  type="number"
                  name="wheelHeight"
                  value={carData.wheelHeight}
                  onChange={handleChange}
                  placeholder="Visina"
                />
                <input
                  type="number"
                  name="wheelDiameter"
                  value={carData.wheelDiameter}
                  onChange={handleChange}
                  placeholder="Promjer"
                />
                <input
                  type="number"
                  name="numberOfHoles"
                  value={carData.numberOfHoles}
                  onChange={handleChange}
                  placeholder="Broj rupa"
                />
              </div>
              <div className="input-grid-2">
                <input
                  type="number"
                  name="price"
                  value={carData.price}
                  onChange={handleChange}
                  placeholder="Cijena"
                />
                <p>/kom</p>
              </div>
            </>
          )}

          {(carData.category === "dijelovi" ||
            carData.category === "ostalo") && (
            <>
              <div className="input-grid-4">
                <input
                  type="number"
                  name="price"
                  value={carData.price}
                  onChange={handleChange}
                  placeholder="Cijena"
                />
                <p>€</p>
              </div>
            </>
          )}

          <label>Link za oglas:</label>
          <input
            type="url"
            name="carLink"
            value={carData.carLink}
            onChange={handleChange}
          />

          <button className="add-btn" type="button" onClick={handlePreview}>
            Pregledaj
          </button>
          <button className="publish-btn" type="submit">
            {editCarId ? "Spremi" : "Objavi"}
          </button>
        </form>

        <div className="filter-controls">
          <select
            className="go-back-btn"
            value={selectedCategory}
            onChange={handleCategoryFilter}
          >
            <option value="all">Sve</option>
            <option value="automobili">Automobili</option>
            <option value="felge">Felge</option>
            <option value="dijelovi">Dijelovi</option>
            <option value="ostalo">Ostalo</option>
          </select>
        </div>

        {previewCarData && (
          <div className="preview-container">
            <h3>Pregled vozila</h3>
            <CarsPreview
              carData={previewCarData}
              handleEditCar={handleEditCar}
            />
          </div>
        )}

        <h3>Dodana vozila</h3>
        <div className="car-list">
          {filteredCarList.map((car, index) => (
            <div key={index} className="admin-news-list">
              <img src={car.images[0]} alt={car.carName} width={100} />
              <h5>{car.carName}</h5>
              <div>
                <button className="add-btn" onClick={() => handleEditCar(car)}>
                  Uredi
                </button>
                <button
                  className="remove-btn"
                  onClick={() => handleDeleteCar(car)}
                >
                  Obriši
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddCars;
