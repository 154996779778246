import React, { useEffect, useState } from "react";
import Cars from "./Cars";
import Main from "../Main/Main";
import KontaktBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

export default function CarsPages() {
  const [carsData, setCarsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState("all"); // State for selected category
  const [message, setMessage] = useState("");

  const carsPerPage = 4;

  useEffect(() => {
    const fetchCarsData = async () => {
      try {
        const docRef = doc(db, "autoconnect", "autoconnect");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const carsList = data.prodajaAuta || [];
          carsList.sort((a, b) => new Date(b.date) - new Date(a.date));

          setCarsData(carsList);
        } else {
          setMessage("Trenutno nema automobila u bazi.");
        }
      } catch (error) {
        setMessage(
          "Došlo je do greške prilikom dohvaćanja automobila: " + error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCarsData();
  }, []);

  const filteredCars = carsData.filter((car) =>
    category === "all" ? true : car.category === category
  );

  const totalCars = filteredCars.length;
  const totalPages = Math.ceil(totalCars / carsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setCurrentPage(1); // Reset to first page when category changes
  };

  const indexOfLastCar = currentPage * carsPerPage;
  const indexOfFirstCar = indexOfLastCar - carsPerPage;
  const currentCars = filteredCars.slice(indexOfFirstCar, indexOfLastCar);

  if (loading) {
    return <div className="center">Učitavanje...</div>;
  }

  return (
    <>
      <Main imageUrl={KontaktBackground} altText="Automobili" />
      <div className="section">
        {message && <div className="message">{message}</div>}

        {/* Category Filter */}
        <div className="filter-controls">
          <select
            className="filter-btn go-back-btn"
            value={category}
            onChange={handleCategoryChange}
          >
            <option value="all">Sve kategorije</option>
            <option value="automobili">Automobili</option>
            <option value="dijelovi">Dijelovi</option>
            <option value="felge">Felge</option>
            <option value="ostalo">Ostalo</option>
            {/* Add more categories if needed */}
          </select>
        </div>

        <div className="grid-2 novosti-pages">
          {currentCars.map((car) => (
            <Cars key={car.carLink} car={car} />
          ))}
        </div>

        <div className="pagination">
          <button
            onClick={() =>
              handlePagination(currentPage > 1 ? currentPage - 1 : 1)
            }
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePagination(i + 1)}
              className={currentPage === i + 1 ? "active" : ""}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() =>
              handlePagination(
                currentPage < totalPages ? currentPage + 1 : totalPages
              )
            }
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  );
}
