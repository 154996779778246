import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  ServisniCentar,
  NaseUsluge,
  ZastupstvaButtons,
} from "../../../Components";
// Language
import { useTranslation } from "react-i18next";
// Images
import AutoconnectBackground from "../../../Images/ostalo/Autoconnect-prednja-strana2.jpg";
import NoProfile from "../../../Images/zaposlenici/No-profile.jpg";
import BrabusAMG1 from "../../../Images/projekti/Brabus-AMG-S-63-Coupe1.jpg";
import BrabusAMG2 from "../../../Images/projekti/Brabus-AMG-S-63-Coupe2.jpg";
import BrabusAMG3 from "../../../Images/projekti/Brabus-AMG-S-63-Coupe3.jpg";
import BrabusWidestar1 from "../../../Images/projekti/Brabus-800-Widestar1.jpg";
import BrabusWidestar2 from "../../../Images/projekti/Brabus-800-Widestar2.jpg";
import BrabusWidestar3 from "../../../Images/projekti/Brabus-800-Widestar3.jpg";

//
export default function Naslovnica() {
  const navigate = useNavigate();
  const [wentBackToHome, setWentBackToHome] = useState(false);

  useEffect(() => {
    const handleNavigation = (event) => {
      if (window.location.pathname === "/") {
        if (!wentBackToHome) {
          setWentBackToHome(true);
          window.history.go(-1);
        }
      } else {
        if (event.state && event.state.previousPath !== "/") {
          navigate("/");
        }
      }
    };

    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [navigate, wentBackToHome]);
  return (
    <>
      <Helmet>
        <title>Autoconnect | Naslovnica</title>
        <meta
          name="description"
          content="AutoConnect d.o.o.. Zagrebačka 33. 10 360 Sesvete. Croatia. OIB:27909788832. MB:05259355. Kontakti. Tel: +385 1 2911 500. Fax: +385 1 2911 430. Email: info@ ..."
        />
      </Helmet>
      <MainNaslovnica />
      <ServisniCentar />
      {/* <!-- -----NAŠE USLUGE----- --> */}
      <div className="border"></div>
      <NaseUsluge />
      <div className="border"></div>
      <ZastupstvaButtons />
      <div className="border"></div>
      <NaslovnicaRecenzije />
      <div className="border"></div>
      <AutoConnect />
      <div className="border"></div>
      <NaslovnicaProjekti />
    </>
  );
}

function MainNaslovnica() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <main>
        <div className="main-box"></div>
        <div className="main-img">
          <img
            style={{ objectPosition: "bottom" }}
            src={AutoconnectBackground}
            alt="Naslovna slika"
          />
          <div className="main-title">
            <h1>DOBRODOŠLI U SVIJET VOZILA</h1>
            <p>
              Autoconnect, u kojem uz popravak Vašeg vozila možete razgledati
              automobile koji predstavljaju sam vrh aktualnih tehnoloških i
              dizajnerskih trendova...
            </p>
            <Link to="/o-nama" className="btn" onClick={() => handleClick("/")}>
              O nama &rarr;
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}

function NaslovnicaRecenzije() {
  return (
    <>
      <section className="section">
        <h2>KLIJENTI O NAMA</h2>
        <div className="recenzije-grid">
          <div className="recenzije">
            <img src={NoProfile} alt="no profile" />
            <h2>Marko K.</h2>
            <p>Sve na nivou , jako pristupacni i korektni!</p>
            <span>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
            </span>
          </div>
          {/* <!--  --> */}
          <div className="recenzije">
            <img src={NoProfile} alt="no profile" />
            <h2>Elvis D.</h2>
            <p>
              Ljubazno osoblje, nisam čekao termin. Kvalitetna usluga. I dobra
              cijena. Za sada sve super. Preporučam...
            </p>
            <span>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
            </span>
          </div>
          {/* <!--  --> */}
          <div className="recenzije">
            <img src={NoProfile} alt="no profile" />
            <h2>Tomislav Š.</h2>
            <p>
              Odličan servis sa profesionalnim djelatnicima i dobrim cijenama.
              Sve pohvale i preporuke.
            </p>
            <span>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
              <i className="fi fi-ss-star"></i>
            </span>
          </div>
        </div>
        <Link
          to="https://www.google.com/search?sa=X&sca_esv=578888506&hl=hr-HR&tbm=lcl&sxsrf=AM9HkKnasBHQanq1AEl3uoHKe526onyheQ:1698948498748&q=Brabus+Hrvatska+Recenzije&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MzIxsjQzMzE2MDK3MDazNDc22MDI-IpR0qkoMam0WMGjqCyxpDg7USEoNTk1ryozK3URK245ADWoMMdSAAAA&rldimm=6242966430278369730&ved=2ahUKEwjHuYmu9KWCAxUJ2gIHHS4LBaIQ9fQKegQIGBAH&cshid=1698948555036103&biw=1582&bih=724&dpr=1.21#lkt=LocalPoiReviews"
          target="_blank"
          className="btn"
          style={{ marginLeft: "0" }}
        >
          Više &rarr;
        </Link>
      </section>
    </>
  );
}

function AutoConnect() {
  return (
    <>
      <section className="section">
        <h2>AUTOCONNECT</h2>
        <div className="grid-3">
          <div className="autoconnect-box">
            <i className="fi fi-ss-globe"></i>
            <h5>Online naručivanje</h5>
          </div>
          {/* <!--  --> */}
          <div className="autoconnect-box">
            <i className="fi fi-ss-stars"></i>
            <h5>Dugogodišnje iskustvo</h5>
          </div>
          {/* <!--  --> */}
          <div className="autoconnect-box">
            <i className="fi fi-ss-diploma"></i>
            <h5>Certificirano osoblje</h5>
          </div>
          {/* <!--  --> */}
          <div className="autoconnect-box">
            <i className="fi fi-ss-usd-circle"></i>
            <h5>Najbolje cijene servisa</h5>
          </div>
          {/* <!--  --> */}
          <div className="autoconnect-box">
            <i className="fi fi-ss-shield-check"></i>
            <h5>12 mjeseci garancije na servis</h5>
          </div>
          {/* <!--  --> */}
          <div className="autoconnect-box">
            <i className="fi fi-ss-menu-dots"></i>
          </div>
        </div>
      </section>
    </>
  );
}

function NaslovnicaProjekti() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };

  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <h2>{t("projekti.title")}</h2>
        <div className="projekt-grid">
          {/*  <!--  --> */}
          <div className="projekt-box">
            <div>
              <h4>Brabus AMG S 63 Coupe</h4>
              <h6>{t("projekti.projectTitle")}</h6>
              <ul>
                <li>
                  <span>&rarr;</span> {t("projekti.brabusAMG.list1")}
                </li>
                <li>
                  <span>&rarr;</span> {t("projekti.brabusAMG.list2")}
                </li>
                <li>
                  <span>&rarr;</span>
                  {t("projekti.brabusAMG.list3")}
                </li>
                <li>
                  <span>&rarr;</span>
                  {t("projekti.brabusAMG.list4")}
                </li>
              </ul>
            </div>
            <div className="projekt-img-box">
              <img
                src={BrabusAMG1}
                alt="Brabus-AMG-S-63-Coupe1"
                onClick={() => openImage(BrabusAMG1)}
              />
              <img
                src={BrabusAMG2}
                alt="Brabus-AMG-S-63-Coupe2"
                onClick={() => openImage(BrabusAMG2)}
              />
              <img
                src={BrabusAMG3}
                alt="Brabus-AMG-S-63-Coupe3"
                onClick={() => openImage(BrabusAMG3)}
              />
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="red-border"></div>
          <div className="projekt-box">
            <div>
              <h4>Brabus 800 WIDESTAR</h4>
              <h6>{t("projekti.projectTitle")}</h6>
              <ul>
                <li>
                  &rarr; <span>{t("projekti.brabusG800.list1")}</span>
                </li>
                <li>
                  &rarr; <span>{t("projekti.brabusG800.list2")}</span>
                </li>
                <li>
                  &rarr; <span>{t("projekti.brabusG800.list3")}</span>
                </li>
                <li>
                  &rarr; <span>{t("projekti.brabusG800.list4")}</span>
                </li>
              </ul>
            </div>

            <div className="projekt-img-box">
              <img
                src={BrabusWidestar3}
                alt="Brabus 800 Widestar"
                onClick={() => openImage(BrabusWidestar3)}
              />
              <img
                src={BrabusWidestar2}
                alt="Brabus 800 Widestar"
                onClick={() => openImage(BrabusWidestar2)}
              />
              <img
                src={BrabusWidestar1}
                alt="Brabus 800 Widestar"
                onClick={() => openImage(BrabusWidestar1)}
              />
            </div>
          </div>
          {/*  <!--  --> */}
        </div>
        <Link
          to="/projekti"
          className="btn"
          style={{ marginLeft: "0" }}
          onClick={() => handleClick("/projekti")}
        >
          {t("projekti.moreBtn")} &rarr;
        </Link>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
