import React, { useState } from "react";
// Fiat Abarath
import FiatAbarath from "../../../Images/projekti/Fiat-Abarth.jpg";
import FiatAbarathFelge from "../../../Images/projekti/Fiat-Abarth-felge.jpg";
// Brabus AMG S 63
import BrabusAMG1 from "../../../Images/projekti/Brabus-AMG-S-63-Coupe1.jpg";
import BrabusAMG2 from "../../../Images/projekti/Brabus-AMG-S-63-Coupe2.jpg";
// Brabus 800 Widestar
import Brabus800Widestar1 from "../../../Images/projekti/Brabus-800-Widestar3.jpg";
import Brabus800Widestar2 from "../../../Images/projekti/Brabus-800-Widestar2.jpg";
// Mercedes Benz A 250
import MercedesBenz1 from "../../../Images/projekti/Mercedes-Benz-A-250-prednja.jpg";
import MercedesBenz2 from "../../../Images/projekti/Mercedes-Benz-A-250-zadnja.jpg";
// Smart EQ fortwo
import SmartEQ from "../../../Images/projekti/Smart-EQ-fortwo.jpg";
// Smart Cabrio plavi
import SmartCabrio1 from "../../../Images/projekti/Smart2.JPG";
import SmartCabrio2 from "../../../Images/projekti/Smart3.JPG";

export default function ProjektiSlider() {
  const [position, setPosition] = useState(0);
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };

  const moveRight = () => {
    setPosition((prevPosition) => (prevPosition + 1) % carBox.length);
  };

  const moveLeft = () => {
    setPosition((prevPosition) =>
      prevPosition === 0 ? carBox.length - 1 : prevPosition - 1
    );
  };

  const carBox = [
    {
      title: "Brabus AMG S 63 Coupe",
      info: [
        "Karbonski paket i zvuk",
        "Pragovi stražnjeg difuzora",
        "Mat crna folija",
        "Brabus aluminijske felge od 21",
      ],
      images: [BrabusAMG1, BrabusAMG2],
    },
    {
      title: "Fiat Abarth",
      info: [
        "Pojasi u boji",
        "Farbanje čeljusti",
        "Alcantara obloge vratiju",
        "Ispušni sistem Akrapović",
      ],
      images: [FiatAbarath, FiatAbarathFelge],
    },
    {
      title: "Brabus G 800 WIDESTAR",
      info: [
        '23" naplatci od kovane legure',
        "Kromirane ispušne cijevi",
        "Dorada interijera",
        "Dodavanje Brabusove grafike",
      ],
      images: [Brabus800Widestar1, Brabus800Widestar2],
    },
    {
      title: "Mercedes Benz A 250",
      info: ["Brabus difuzor", "Ispušni sistem"],
      images: [MercedesBenz1, MercedesBenz2],
    },
    {
      title: "Smart EQ fortwo",
      info: ["Prednji spojler", "Bočni pragovi"],
      images: [SmartEQ],
    },
    {
      title: "Smart",
      info: ["Wrap"],
      images: [SmartCabrio1, SmartCabrio2],
    },
  ];

  return (
    <>
      <section className="section">
        <h1>NAŠI PROJEKTI</h1>
        <div className="cars-slide">
          <button id="left-slide" className="btn-slide" onClick={moveLeft}>
            <i className="fi fi-ss-angle-double-small-left"></i>
          </button>
          <div className="slider">
            {carBox.map((car, index) => (
              <div
                key={index}
                className="cars-box"
                style={{ display: index === position ? "grid" : "none" }}
              >
                <div className="cars-info">
                  <h3>NAŠI PROJEKTI</h3>
                  <p>Na ovom vozilu doradili smo:</p>
                  <ul>
                    {car.info.map((item, i) => (
                      <li key={i}>
                        <span>&rarr;</span> {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="img-box">
                  {car.images.map((image, i) => (
                    <img
                      key={i}
                      src={image}
                      alt={car.title}
                      onClick={() => openImage(image)}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <button id="right-slide" className="btn-slide" onClick={moveRight}>
            <i className="fi fi-ss-angle-double-small-right"></i>
          </button>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
