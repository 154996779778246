import React from "react";

const CarsPreview = ({ carData, handleEditCar }) => {
  return (
    <div className="news-preview">
      {carData.images &&
        carData.images.map((image, index) => (
          <img
            key={index}
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt={carData.imageAlt || `Slika ${index + 1}`}
          />
        ))}
      <span className="image-author">{carData.imageAuthor}</span>
      <h3>{carData.carName}</h3>
      <p>
        <strong>Cijena:</strong> {carData.price} KM
      </p>
      <p>
        <strong>Prijeđeni kilometri:</strong> {carData.kilometers} km
      </p>
      <p>
        <strong>Snaga motora:</strong> {carData.enginePower} KS
      </p>
      <p>
        <strong>Vrsta motora:</strong> {carData.engineType}
      </p>
      <p>
        <strong>Godina proizvodnje:</strong> {carData.productionYear}
      </p>
      <p>
        <strong>Vrsta mjenjača:</strong> {carData.transmission}
      </p>
      <p>
        <strong>Link za oglas:</strong>{" "}
        <a href={carData.carLink} target="_blank" rel="noopener noreferrer">
          {carData.carLink}
        </a>
      </p>
      <p>
        <strong>Email: </strong> prodaja@autoconnect.hr
      </p>
      <div className="car-contact">
        <div className="contact-mail-box">
          <h4>Sascha</h4>
          <a href="tel:+385913344332">091 334 4332</a>
        </div>
        <div className="contact-mail-box">
          <h4>Krešo Brkljačić</h4>
          <a href="tel:+385994676117">099 467 6117 </a>
        </div>
        <div className="contact-mail-box">
          <h4>AutoConnect</h4>
          <a href="tel:+38512911500">01 2911 500 </a>
        </div>
      </div>
    </div>
  );
};

export default CarsPreview;
