import OnamaBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import OnamaImage from "../../../Images/ostalo/Autoconnect-prednja.jpg";
// Naš tim
import KrešimirBrkljačić from "../../../Images/zaposlenici/Krešimir-Brkljačić.jpg";
import DamirJakopić from "../../../Images/zaposlenici/Damir-Jakopić.jpg";
import NikolaVugrinec from "../../../Images/zaposlenici/Nikola-Vugrinec.jpg";
import IvicaVizec from "../../../Images/zaposlenici/Ivica-Vizec.jpg";
import NoProfile from "../../../Images/zaposlenici/No-profile.jpg";
//
import Main from "../Main/Main";
import { useState } from "react";
import { Helmet } from "react-helmet";

//
export default function Onama() {
  return (
    <>
      <Helmet>
        <title>Autoconnect | O nama</title>
        <meta
          name="description"
          content="Tvrtka AutoConnect nastavlja tradiciju, zaljubljenicima u automobile vrhunskih performansi. Sinonim za visokosofisticirane prerade Mercedes Benz i Porsche ..."
        />
      </Helmet>
      <Main imageUrl={OnamaBackground} altText="Naslovna slika" />
      <OnamaDescirption />
      <div className="border"></div>
      <OnamaVrijednosti />
      <div className="border"></div>
      <OnamaTim />
    </>
  );
}

function OnamaDescirption() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };

  return (
    <>
      <section id="section-about" className="section">
        <h1>O NAMA</h1>
        <div className="about-grid">
          {/*   <!--  --> */}
          <div className="about-box">
            <h5>
              DOBRODOŠLI NA NAŠU STRANICU TVRTKE<span> AUTOCONNECT</span>
            </h5>
            <p>Poštovani posjetitelji,</p>
            <p>
              prije nego se pobliže predstavimo, dopustite da Vam zaželimo
              dobrodošlicu u svijet automobila, tuninga, vrhunskih dorada i svih
              onih uzbuđenja koja nam pruža fascinacija automobilima. Jednom
              riječju, dobrodošli u AutoConnect svijet.
            </p>
          </div>

          <div className="about-box">
            <img
              src={OnamaImage}
              alt="Autoconnect kuća"
              onClick={() => openImage(OnamaImage)}
            />
          </div>

          {/*  <!--  --> */}
        </div>
        <div className="about-box">
          <p>
            Ljubiteljima automobila na poznatoj adresi, Zagrebačka 33 u
            Sesvetama, smjestio se prodajno servisni centar tvrtke Autoconnect u
            kojem uz popravak Vašeg vozila možete razgledati automobile koji
            predstavljaju sam vrh aktualnih tehnoloških i dizajnerskih trendova
            (Mercedes Benz, Porsche, Land Rover…), ali i automobile koji potječu
            iz nekih prošlih, nezaboravljenih vremena.
          </p>
          <p>
            Automobile koji su svojim inovativnim rješenjima, bezvremenskim
            dizajnom te romantikom i neospornim šarmom minulih dana,
            determinirali razvoj autoindustrije kakvu danas poznajemo.
          </p>
        </div>
        {/*  <!--  --> */}
        <div className="about-border"></div>
        {/*  <!--  --> */}
        <div className="about-box">
          <p>
            Prodajno servisni centar tvrtke Autoconnect, na 1300m2 raspoređenih
            na dva kata, uz moderne i oldtimer automobile nudi i veliki izbor
            dodatne opreme uključujući i čitav asortiman renomiranih tvrtki koje
            zastupamo, prije svega BRABUS, TECHART i HAMMAN i po tome smo
            jedinstveni na hrvatskom auto tržištu.
          </p>
          <p>
            Tvrtka AutoConnect nastavlja tradiciju, zaljubljenicima u automobile
            vrhunskih performansi. Sinonim za visokosofisticirane prerade
            Mercedes Benz i Porsche automobila, prije svega legendarne G klase
            od kojih one najljepše, često viđamo na cesti.
          </p>
        </div>
        {/*  <!--  --> */}
        <div className="about-border"></div>
        {/*  <!--  --> */}

        <div className="about-box">
          <p>
            Uz prodaju i servis za sva vozila, kod nas je moguće obaviti i sve
            vrste limarskih i lakirerskih radova te izvršiti popravak vozila po
            prijavi štete.
          </p>
          <p>
            U sustavu smo certificiranog Mercedes Benz servisa, uključujući
            mogućnost upisa u digitalnu servisnu bazu. Servisna se knjižica
            vozila, po obavljenom servisu, potvrđuje i registrira u Mercedes
            Benz centralnom sustavu podataka.
          </p>
        </div>
        {/* <!--  --> */}
        <div className="about-border"></div>
        {/* <!--  --> */}
        <div className="about-box">
          <p>
            Dugogodišnje iskustvo, ulaganja u nova znanja i tehnologije u korak
            sa svjetskim trendovima, čine tvrtku AutoConnect pouzdanim servisnim
            partnerom kojem se klijenti rado vraćaju.
          </p>
          <p>
            Iskustvo naših djelatnika, oprema našeg prodajno servisnog centra te
            posvećenost visokim standardima naših principala snaga je koja
            obvezuje, ali i jamči vrhunsku i visokokvalitetnu uslugu prilikom
            kupnje i servisiranja Vašeg automobila. Zato s veseljem očekujemo
            Vaš posjet.
          </p>
        </div>

        {/*  <!--  --> */}

        <p>Dopustite da Vas oduševimo…</p>
        <h6>~ KREŠIMIR BRKLJAČIĆ, direktor</h6>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}

function OnamaVrijednosti() {
  return (
    <>
      <section id="section-vrijednosti" className="section">
        <h2>NAŠE VRIJEDNOSTI</h2>
        <p>
          Prolazimo opsežnu tvorničku obuku kako bismo Vam mogli pružiti znanje
          potrebno za kvalitetno servisiranje, tuning & doradu vozila.
        </p>
        <ul className="vrijednosti-box">
          <li>
            <i className="fi fi-ss-badge-check"></i>
            Vrhunska i visokokvalitetna usluga
          </li>
          <li>
            <i className="fi fi-ss-diploma"></i> Kvalificirano i školovano
            osoblje
          </li>
          <li>
            <i className="fi fi-ss-time-fast"></i> Brza nabava dijelova za
            servis
          </li>
          <li>
            <i className="fi fi-ss-shield-check"></i> Vrhunska briga o
            klijentima
          </li>
          <li>
            <i className="fi fi-ss-briefcase"></i> Profesionalni servis
          </li>
        </ul>
      </section>
    </>
  );
}

function OnamaTim() {
  return (
    <>
      <section id="section-team" className="section">
        <h2>NAŠ TIM</h2>
        <div className="team-grid">
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={KrešimirBrkljačić} alt="Krešimir Brkljačić" />
            <div className="team-text">
              <h5>Krešimir Brkljačić</h5>
              <span>
                <i className="fi fi-ss-briefcase"></i>
                Direktor
              </span>
              <a href="mailto: kresimir@autoconnnect.hr">
                <i className="fi fi-ss-at"></i>kresimir@autoconnnect.hr
              </a>
              <a href="tel:0994676117">
                <i className="fi fi-ss-phone-call"></i>0994676117
              </a>
            </div>
          </div>

          {/*  <!--  --> */}
          <div className="team-box">
            <img src={DamirJakopić} alt="Damir Jakopić" />
            <div className="team-text">
              <h5>Damir Jakopić</h5>
              <span>
                <i className="fi fi-ss-users-alt"></i>
                Voditelj limarije
              </span>
              <a href="mailto: limarija@autoconnnect.hr">
                <i className="fi fi-ss-at"></i>limarija@autoconnnect.hr
              </a>
              <a href="tel: 012911500">
                <i className="fi fi-ss-phone-office"></i>012911500
              </a>
              <a href="tel: 0912343445">
                <i className="fi fi-ss-phone-call"></i>0912343445
              </a>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NoProfile} alt="Ivana Čibarić" />
            <div className="team-text">
              <h5>Ivana Čibarić</h5>
              <span>
                <i className="fi fi-ss-computer"></i>
                Voditeljica knjigovodstva
              </span>
              <a href="mailto: limarija@autoconnnect.hr">
                <i className="fi fi-ss-at"></i>knjigovodstvo@autoconnnect.hr
              </a>
              <a href="tel: 012911500">
                <i className="fi fi-ss-phone-office"></i>012911500
              </a>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NikolaVugrinec} alt="Nikola Vugrinec" />
            <div className="team-text">
              <h5>Nikola Vugrinec</h5>
              <span>
                <i className="fi fi-ss-car-crash"></i>
                Autolimar
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NoProfile} alt="Siniša Barberić" />
            <div className="team-text">
              <h5>Siniša Barberić</h5>

              <span>
                <i className="fi fi-ss-car-crash"></i>
                Autolimar
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={IvicaVizec} alt="Ivica Vizec" />
            <div className="team-text">
              <h5>Ivica Vizec</h5>

              <span>
                <i className="fi fi-ss-car-mechanic"></i>
                Mehaničar
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NoProfile} alt="Ivica Vizec" />
            <div className="team-text">
              <h5>Josip Medved</h5>

              <span>
                <i className="fi fi-ss-car-mechanic"></i>
                Automehaničar
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
        </div>
      </section>
    </>
  );
}
