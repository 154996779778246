import { useEffect } from "react";
import PolitikaBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import Main from "../Main/Main";

export default function PolitikaPrivatnosti() {
  useEffect(() => {
    document.title = "Autoconnect | Opći uvjeti poslovanja";
  }, []);
  return (
    <>
      <Main imageUrl={PolitikaBackground} altText="Background privacy" />

      <section className="section" id="opci-uvjeti">
        <h2>OPĆI UVJETI POSLOVANJA</h2>
        <div className="privacy-text">
          <p>
            Korisnici i kupci su dužni prije početka korištenja web stranice
            (www.autoconnect.hr) pružatelja usluga Autoconnect sa sjedištem u
            Zagrebu, Zagrebačka 33 - 10360 Sesvete upoznati se s Općim uvjetima
            korištenja web stranice. Ukoliko imaju dodatnih pitanja ili
            nejasnoća vezanih uz uvjete korištenja, mogu se obratiti na adresu
            e-pošte knjigovodstvo@autoconnect.hr
          </p>
          <p>
            Slanjem upita, potpisom ugovora ili neposrednim kontaktom, suglasni
            ste da Autoconnect dobrovoljno stavljate na raspolaganje svoje i
            podatke trgovačkog društva koje predstavljate ili zastupate.
            Dozvoljavate da se isti koriste u cilju zaštite osobnih interesa u
            svim poslovima koje ugovarate s Autoconnect-om.
          </p>
          <p>
            Autoconnect se obavezuje pružati zaštitu osobnim podacima kupaca, na
            način da prikuplja samo nužne, osnovne podatke o kupcima/korisnicima
            koji su nužni za ispunjenje naših poslovnih obveza.
          </p>
          <p>
            Svi se podaci o korisnicima čuvaju i dostupni su samo podaci nužni
            za obavljanje posla. Svi djelatnici Autoconnect-a i poslovni
            partneri odgovorni su za poštivanje načela zaštite privatnosti.
          </p>
          <p>
            Skupljanje i obrada osobnih podataka u svrhu realizacije dogovorenih
            poslova uključuje i prosljeđivanje osobnih podataka klijenta trećim
            osobama i trgovačkim društvima u Republici Hrvatskoj i inozemstvu.
            Trećim osobama smatraju se sve fizičke osobe i trgovačka društva
            koje su neophodne za realizaciju ugovorenih poslova.
          </p>
          <p>
            Vanjske poveznice Autoconnect sadržava linkove/vanjske poveznice na
            web stranice izvan vlastite internetske stranice. Autoconnect
            linkove objavljuje u dobroj namjeri i ne može se smatrati odgovornim
            za sadržaje izvan ove internetske stranice.
          </p>
          <p>
            Dužnost i obaveza korisnika je koristiti web stranicu u skladu s
            pozitivnim propisima te općim moralnim i etičkim načelima.
            Autoconnect ima pravo u svakom trenutku vršiti kontrolu sadržaja web
            stranice kako bi osigurao poštivanje Općih uvjeta i pozitivnih
            propisa. Izmjene uvjeta korištenja važeće su odmah po objavi na web
            stranici www.autoconnect.hr
          </p>
        </div>
      </section>
    </>
  );
}
