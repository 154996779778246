import React from "react";
import { Link } from "react-router-dom";

const Cars = ({ car }) => {
  if (!car) {
    return null; // If car data is not provided, render nothing
  }

  // Function to render additional details based on category
  const renderCarDetails = () => {
    if (car.category === "felge") {
      return (
        <>
          <p>Promjer: {car.wheelDiameter}&#8243;</p> {/* Inči za promjer */}
          <p>Širina: {car.wheelWidth} cm</p> {/* Širina felge */}
          <p>Visina: {car.wheelHeight} cm</p> {/* Visina felge */}
          <p>Broj rupa: {car.numberOfHoles}</p> {/* Broj rupa */}
        </>
      );
    } else if (car.category === "dijelovi" || car.category === "ostalo") {
      return null; // For parts and others, only show the name and price
    } else {
      return (
        <>
          <p>{car.category}</p>
          <p>{car.productionYear} god.</p>
          <p>{car.enginePower} kW</p>
          <p>{car.kilometers} km</p>
        </>
      );
    }
  };

  return (
    <Link
      to={car.carLink}
      target="_blank"
      rel="noopener noreferrer"
      className="car-item"
    >
      {/* Display car images */}
      {car.images &&
        car.images.map((imageUrl, index) => (
          <img
            key={`${car.carLink}-image-${index}`}
            src={imageUrl}
            alt={car.carName}
          />
        ))}

      <div className="car-item-info">
        <h3>{car.carName}</h3>
        <div className="car-item-more-info">{renderCarDetails()}</div>
        <h4>
          {car.price} € <span>{car.category === "felge" && "/ kom"}</span>
        </h4>{" "}
        {/* Add '/ kom' for felge category */}
      </div>
    </Link>
  );
};

export default Cars;
