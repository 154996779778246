import Brabus from "../../../Images/zastupstva/brabus/Brabus2.png";
import BrabusLogo from "../../../Images/zastupstva/brabus.png";
import Hamann from "../../../Images/zastupstva/hamann/Hamann.png";
import HamannLogo from "../../../Images/zastupstva/hamann.png";
import Techart from "../../../Images/zastupstva/techart/Techart.jpg";
import TechartLogo from "../../../Images/zastupstva/techart.png";
import { HashLink } from "react-router-hash-link";

export default function ZastupstvaButtons() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="section">
        <h2>ZASTUPSTVA</h2>
        <div className="zastupstva-grid-btn">
          <HashLink
            smooth
            to="/zastupstva/brabus"
            className="zastupstva-box"
            onClick={() => handleClick("/zastupstva/brabus")}
          >
            <img src={Brabus} className="zastupstva-bg" alt="Brabus" />
            <img src={BrabusLogo} alt="Brabus" className="zastupstva-brand" />
          </HashLink>
          {/*  <!--  --> */}
          <HashLink
            smooth
            to="/zastupstva/hamann"
            className="zastupstva-box"
            onClick={() => handleClick("/zastupstva/hamann")}
          >
            <img src={Hamann} className="zastupstva-bg" alt="Hamann" />
            <img src={HamannLogo} alt="Hamann" className="zastupstva-brand" />
          </HashLink>
          {/* <!--  --> */}
          <HashLink
            smooth
            to="/zastupstva/techart"
            className="zastupstva-box"
            onClick={() => handleClick("/zastupstva/techart")}
          >
            <img src={Techart} className="zastupstva-bg" alt="Techart" />
            <img src={TechartLogo} alt="Techart" className="zastupstva-brand" />
          </HashLink>
        </div>
      </section>
    </>
  );
}
