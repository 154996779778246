// src/Components/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import KontaktBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import Main from "../../Pages/Main/Main";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate("/admin");
    } catch (error) {
      setError("Krivi mail ili password. Molim pokušajte ponnovo.");
    }
  };

  return (
    <div className="section">
      <Main imageUrl={KontaktBackground} altText="ADMIN LOGIN" />
      <h2>LOGIN</h2>
      <div className="login">
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error && <div className="error-message">{error}</div>}
          <div>
            {" "}
            <button className="btn" type="submit">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
